import * as React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import Layout from "../components/layouts/index-layout"
import * as entities from "entities"
import { IBlogExcerpt } from "../components/blog/excerpt"

interface IData {
  allMdx: {
    edges: {
      node: {
        fields: {
          slug: string
        }
        frontmatter: {
          title: string
        }
      }
    }[]
  }
}

const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

const ByYear = ({ data, pageContext}: { data: IData, pageContext: any}) => {
  let currentPrefix=""
  let months = []
  let currentMonth = []
  let year = pageContext.glob.substr(1, 4)
  data.allMdx.edges.forEach(edge => {
    let pfx = edge.node.fields.slug.substr(0, 8)
    if (pfx !== currentPrefix) {
      if (currentPrefix !== "") {
        months.push({
          path: currentPrefix,
          name: monthNames[parseInt(currentPrefix.substr(6, 2)) - 1],
          posts: currentMonth
        })
      }
      currentMonth = []
      currentPrefix = pfx
    }
    currentMonth.push(edge)
  })

  months.push({
    path: currentPrefix,
    name: monthNames[parseInt(currentPrefix.substr(6, 2))-1],
    posts: currentMonth
  })
  // return (<pre>{JSON.stringify(months, null, 4)}</pre>)
  return (
  <Layout>
    <h1 className="text-3xl">{year}</h1>
    {months.map((month, idx) => (
      <div key={idx}>
      <h2 className="text-2xl hover:underline"><Link to={month.path}>{month.name}</Link></h2>
      <ul>
      {month.posts.map((edge, idx) => (
        <li key={idx} className="ml-6 my-1 hover:underline"><Link to={edge.node.fields.slug}>{entities.decodeHTML5(edge.node.frontmatter.title)}</Link></li>
      ))}
      </ul>
      </div>
      ))}
  </Layout>
    )
}

export const query = graphql`
    query yearBlog($glob: String!) {
        allMdx(sort: {fields: frontmatter___date, order: DESC}, filter: {fields: {collection: {eq: "posts"}, slug: {glob: $glob}}}) {
            edges {
                node {
                    fields {
                        slug
                    }
                    frontmatter {
                        title
                    }
                }
            }
        }
    }
`

export default ByYear